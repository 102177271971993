import React from 'react'
import SEO from '~/components/seo'
import styled from '@emotion/styled'
import {breakpoints} from '~/utils/styles'

const NotFoundPage = () => {
	const Aside = styled.aside`
	  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/right-edges.png");
	  background-position: top right;
	  background-repeat: no-repeat;
	  background-size: 25px 100%;
	  & img {
		  display: block;
		  height: auto;
		  width: 100%;
	  }
	  
	  @media (media: max-wdith: ${breakpoints.m}px){
		background-image: none;
		background-color: white;
		
		& img {
			max-width: 300px;
		}
	  }
	`
	const Outer = styled.div`
	  background: -webkit-gradient(linear, left top, right top, color-stop(50%, white), color-stop(50%, #383838));
	  background: linear-gradient(to right, white 50%, #383838 50%);
	  font-family: "Roboto", sans-serif;
	  font-size: 18px;
	  font-weight: 500;
	  line-height: 1.5;
	  color: white;
	  display: -webkit-box;
	  display: flex;
	  -webkit-box-align: center;
			  align-items: center;
	  height: 100vh;
	  max-width: 1000px;
	  width: calc(100% - 4rem);
	  margin: 0 auto;
	  
	  & > * {
		display: -webkit-box;
		  display: flex;
		  -webkit-box-orient: vertical;
		  -webkit-box-direction: normal;
				  flex-flow: column;
		  -webkit-box-align: center;
				  align-items: center;
		  -webkit-box-pack: center;
				  justify-content: center;
		  height: 100vh;
		  max-width: 500px;
		  width: 100%;
		  padding: 2.5rem;
	  }
	  
	  @media (media: max-wdith: ${breakpoints.m}px){
		background: #383838;
		font-size: 16px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
            flex-flow: column;
	  }
	  & > * {
		max-width: 700px;
		height: 100%;
	  }
	`
	const Mainer = styled.div`
		text-align: center;
		& h1 {
	      font-family: "Fontdiner Swanky", cursive;
		  font-size: 4rem;
		  color: #c5dc50;
		  margin-bottom: 1rem;
		}
		& p {
			margin-bottom: 2.5rem;
		}
		& button {
		  font-family: "Fontdiner Swanky", cursive;
		  font-size: 1rem;
		  color: #383838;
		  border: none;
		  background-color: #f36a6f;
		  padding: 1rem 2.5rem;
		  -webkit-transform: skew(-5deg);
				  transform: skew(-5deg);
		  -webkit-transition: all 0.1s ease;
		  transition: all 0.1s ease;
		  cursor: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/cursors-eye.png"), auto;
		}
		& button:hover {
		  background-color: #c5dc50;
		  -webkit-transform: scale(1.15);
				  transform: scale(1.15);
		}
		
		@media (media: max-wdith: ${breakpoints.m}px){
		  
	  }
	`
	return (
      <>
		<SEO title="Nothing to see here..."/>
		<Outer>
		  <Aside><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/Mirror.png" alt="404 Image" />
		  </Aside>
		  <Mainer>
			<h1>Sorry!</h1>
			<p>
			  Either you aren't cool enough to visit this page or it doesn't exist.
			</p>
			<a href="/products"><button type="button">Continue Shopping!</button></a>
		  </Mainer>
		</Outer>
	  </>
	)
}

export default NotFoundPage
